import { graphql } from "gatsby";
import AboutTemplate from "./About";

export const pageQuery = graphql`
  query AboutDataQuery($slug: String!, $bgImg: String) {
    site {
      siteMetadata {
        socialWebsites {
          facebook
          instagram
        }
      }
    }
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        bgPosition
      }
    }
    image: file(relativePath: { eq: $bgImg }) {
      ...BackgroundImageFragment
    }
  }
`;

export default AboutTemplate;
