import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Article from "../Article/";
import Content from "../Article/Content";
import Footer from "../Article/Footer";
import Header from "../Article/Header";

const styles = (theme) => ({
  moveRight: {
    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      float: "right",
      maxWidth: "45%",
      textAlign: "right",
    },
  },
});

const Page = ({ page, footnote, classes, pageContent }) => {
  return (
    <Article className={classes.moveRight}>
      <Header title={page.frontmatter.title} />
      {pageContent ? <Content>{pageContent}</Content> : <Content html={page.html} />}
      {footnote && <Footer html={footnote.html} />}
    </Article>
  );
};

Page.propTypes = {
  page: PropTypes.shape({
    html: PropTypes.string,
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  image: PropTypes.object,
  footnote: PropTypes.object,
  classes: PropTypes.object,
  pageContent: PropTypes.node,
};

export default withStyles(styles, { withTheme: true })(Page);
