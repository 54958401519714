import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import SocialButton from "../../components/SocialButton";
import Layout from "../../components/Layout";
import Container from "../../components/Container";
import Background from "../../components/Background";
import Page from "../../components/Page";

const styles = (theme) => ({
  btnsContainer: {
    extend: theme.mainButtonsTopPosition,
    padding: "0 calc(86px + 0.3em)",

    "& > .social-button + .social-button": {
      marginLeft: "0.3em",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.SM}px)`]: {
      [`@media (max-width: ${theme.mediaQueryTresholds.M}px)`]: {
        padding: "0 calc(86px + 1.65em)",
      },

      "& > .social-button + .social-button": {
        marginLeft: "1.2em",
      },
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px) and (max-width: ${theme.mediaQueryTresholds.L}px)`]: {
      padding: "0 calc(96px + 1.65em)",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      padding: "0 calc(106px + 1.65em)",
    },

    ".sidebar &": {
      display: "none",
    },
  },
});

const SocialButtons = withStyles(styles, { withTheme: true })(({ socialWebsites, classes }) => (
  <div className={classes.btnsContainer}>
    {Object.keys(socialWebsites).map((key) => (
      <SocialButton key={key} url={socialWebsites[key]} siteId={key} />
    ))}
  </div>
));

const AboutTemplate = ({ data, location }) => {
  const { page = {}, site } = data;
  const bgPosition = page.frontmatter.bgPosition || "66% center";

  return (
    <Layout location={location}>
      <Container location={location}>
        <Background
          fluid={data.image.childImageSharp.fluid}
          objectFit="cover"
          objectPosition={bgPosition}
        />
        <Page page={page} image={data.image} />
      </Container>
      <SocialButtons socialWebsites={site.siteMetadata.socialWebsites} />
    </Layout>
  );
};

AboutTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AboutTemplate;
